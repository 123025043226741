.customSelect {
  position: relative;
  z-index: 1;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  max-width: 470px;

  .selectButton {
    color: var(--Gray);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 26px;
    border-radius: 12px;
    border: 1px solid var(--Medium-gray);
    background: var(--Medium-blue);
    outline: none;
    cursor: pointer;
  }

  .selectRequiredInput {
    opacity: 0 !important;
    height: 0 !important;
    padding: 1 !important;
    border: 0 !important;
  }

  .selectedValue {
    display: flex;
    gap: 8px;
    text-align: left;
  }

  .arrow {
    transition: transform ease-in-out 0.3s;
    transform: rotate(90deg);
  }

  &.active .arrow {
    transform: rotate(-90deg);
  }

  &.active .selectDropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.selectDropdown {
  position: absolute;
  z-index: 1;
  list-style: none;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--Black);
  border: 1px solid var(--Medium-gray);
  border-radius: 12px;
  padding: 8px;
  margin-top: 10px;
  overflow: hidden;
  overflow-y: auto;
  transition: 0.5s ease;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;

  li {
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    align-items: center;

    label {
      width: 100%;
      padding: 12px 20px;
      cursor: pointer;
    }
  }
  
  li:hover,
  input:checked ~ label {
    color: var(--White);
    background-color: var(--Medium-blue);
  }

  input[type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
}
